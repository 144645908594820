@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.about_page,
.cabinets_page,
.cabinets_details_page,
.career_page,
.contact_page,
.gallery_page,
.game_mix_details_page,
.game_mix_page,
.games_page,
.home_page,
.news_page,
.news_details_page,
.slot_game_details,
.slot_games_page,
.support_page,
.show-products-container .games,
.show-products-container .cabinets,
.show-products-container .mobile-cabinets1,
.show-products-container .mobile-cabinets2,
.show-products-container .news_tab,
.show-products-container .gallery_tab {
  opacity: 1;
  animation: example;
  animation-duration: 1s;
}

.mobile-cabinets1.show,
.mobile-cabinets2.show {
  left: 0 !important
}

@keyframes example {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.full-screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.app-content.opacity {
  opacity: 1;
  transition: 1s;
}

a:hover {
  color: #7431A8 !important;
}

.text-link:hover {
  color: #7431A8 !important;
}

.text-justify {
  text-align: justify;
}

.logo {
  max-width: fit-content;
}

.home-logo {
  width: 300px !important;
}

.logo2 {
  width: 140px;
  margin: 0 auto;
  z-index: 1;
}

.landing-animation {
  position: absolute;
  z-index: 4;
}

.landing-animation,
.landing-animation canvas {
  background: rgba(36, 32, 53, 1);
  width: 100vw !important;
  height: 100vh !important;
}

.landing-animation.opacity {
  width: 100% !important;
  overflow: hidden;
  opacity: 0;
  transition: 1s;
}

.home-logo {
  width: 205px;
  margin: 0 auto;
  padding-top: 10px;
  top: -25px;
}

.cover_image {
  position: relative;
}

.cover_image img {
  width: 100%;
  height: 100vh;
}

.cover_image p {
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 645px;
  font-size: 15px;
  color: #fff;
}

.home-bottom-text {
  position: absolute;
  bottom: 0;
}

.mobile-navbar {
  color: #fff;
}

.mobile-navbar li,
.mobile-home-navbar li {
  list-style: none;
  padding-top: 10px;
}

.mobile-navbar li:not(:last-child) {
  padding-bottom: 10px;
}

.mobile-navbar a,
.mobile-home-navbar a {
  color: #00285E;
  text-decoration: none;
}

.mobile-navbar-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  overflow-x: hidden;
  z-index: 2;
  color: #fff;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  font-family: "Lato";
  transition: width 0.5s;
  box-shadow: 0px 5px 10px #00285E;
}

.menu_list {
  width: 70vw;
}

.menu_list {
  font-family: "Lato", sans-serif;
}

.menu_list div {
  border-bottom: solid 1px #ddd;
}

.mobile-navbar-menu .menu_list {
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
}

.menu_list .mobileMenu_list_item:not(:last-child) {
  border-bottom: solid 1px #ddd;
}

.mobileMenu_header {
  border-bottom: solid 2px #ddd;
  min-width: 70vw;
}

.mobileMenu_header_item {
  background-color: #fff;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.mobileMenu_header img {
  max-width: 44%;
}

.mobileMenu_header a,
.mobileMenu_header a:hover,
.mobileMenu_header a:focus {
  color: #fff;
}

.mobileMenu_header {
  max-width: 100%;
  padding: 10px;
}

.mobile_header .imgLink {
  display: block;
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.mobileMenu_header p {
  margin-bottom: 0;
  margin-top: 6px;
}

.mobileMenu_list_item i {
  padding-left: 5px;
}

.previous_tab {
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  padding: 10px 24px;
  border-bottom: solid 1px #ddd;
}

.previous_tab i {
  font-size: 20px !important;
  color: #fff !important;
  padding-right: 10px;
}

.sidebar-container {
  position: fixed;
  z-index: 3;
}

.sidebar-container a,
.sidebar-container .star {
  color: #fff !important;
}

.sidebar-container .icons,
.sidebar-container .text {
  position: fixed;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
}

.sidebar-container .icons {
  z-index: 1;
}

.sidebar-container .icons div {
  box-shadow: 0px 3px 3px #515151;
}

.sidebar-container .text div {
  box-shadow: 0px 3px 3px #515151;
}

.icons div {
  height: 40px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  right: -87px;
  transition: right 0.5s;
}

.icons div:hover {
  right: 0;
}

.text div {
  width: 150px;
  height: 40px;
  background-color: #fff;
  opacity: 0;
  transition: 0.5s;
}

.sidebar-container .card {
  border-radius: 0;
  border: none;
  padding: 10px;
}

.sidebar-container i {
  font-size: 20px !important;
  transition: 5s ease;
}

.opacity-low {
  background-color: #fff;
  opacity: 0;
}

.opacity-high {
  opacity: 1;
}

.move-left {
  right: 110px;
  z-index: 5;
}

.sidebar_box::-webkit-scrollbar,
.all-news::-webkit-scrollbar {
  width: 10px;
}

.sidebar_box::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 10px;
}

.all-news::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-bottom: 10px;
}

.sidebar_box::-webkit-scrollbar-thumb,
.all-news::-webkit-scrollbar-thumb {
  background: #E4E6E9;
  border-radius: 10px;
}

.sidebar_box::-webkit-scrollbar-thumb:hover,
.all-news::-webkit-scrollbar-thumb:hover {
  background: #D7D8DB;
}

.sidebar_card p {
  line-height: 1;
}

.sidebar_card .sidebar_product {
  transition: right 0.5s;
}

.sidebar_box {
  position: fixed;
  width: 0px;
  top: 0;
  right: 0;
  z-index: 4;
  background-color: #fff;
  overflow: scroll;
  overflow-x: hidden;
  transition: width 0.5s;
  padding: 0;
  bottom: 0;
}

.sidebarBox_opened {
  width: 340px;
}

.sidebarProduct_opened {
  transition: margin-right 1s;
  margin-right: 340px;
}

.sidebarProduct_closed {
  right: 0;
}

.sidebar_content {
  position: relative;
  width: 330px;
  padding: 0 40px;
  color: #00285e;
}

.sidebar_content i {
  font-size: 20px !important;
}

.sidebar_content a {
  text-decoration: none;
  color: #00285e;
}

.sidebar_content a:hover {
  color: #778dc7;
}

.sidebar_title {
  font-family: "Lato";
  font-weight: 600;
  width: 100%;
}

.sidebar_content p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.sidebar_content img {
  margin-top: 20px;
}

.sidebar_content a:not(:last-child) .sidebar_content_card {
  border-bottom: 1px solid #ddd;
}

.sidebar_opened {
  padding: 20px 40px;
}

.sidebar_closed {
  padding: 0;
}

.mobileMenu_header_item p {
  font-size: 12px;
  font-weight: 600;
}

.mobile_header_card {
  height: 90px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
}

.mobile_header_card a:hover {
  color: #fff !important;
}

.mobile_header_card i {
  font-size: 24px !important;
}

.chevron_container {
  padding: 15px 15px;
  background: linear-gradient(90deg, #6D56D5 0%, #7857D2 16%, #8051CE 33%, #9358D4 50%, #A454D2 67%, #D058CD 84%, #D062D1 100%);
  border-bottom: none !important;
}

.chevron {
  height: 13px;
  width: 13px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  border-bottom: none !important;
  transform: rotate(45deg);
  position: relative;
  right: 3px;
}

.slider-container div:first-child {
  justify-content: space-between !important;
}

.slider-container button,
.whitegray_background_content button,
.show-products-container button {
  background: none;
}

.slider-container .card-img-top {
  width: auto;
  height: 300px;
}

.slider-chevron {
  height: 25px;
  width: 25px;
  border-top: solid 3px #000;
  border-right: solid 3px #000;
  border-bottom: none !important;
  transform: rotate(45deg);
  position: relative;
  right: 3px;
}

div[role="tabpanel"] div:first-child {
  width: 100%;
}

.submenu {
  background-color: #1D1A2B;
  box-shadow: inset 0 7px 9px -7px #29233D;
  position: absolute;
  width: 100%;
  z-index: 2;
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  padding-right: 30px;
  padding-left: 30px;
  font-family: "Lato", sans-serif;
}

.submenu.active {
  max-height: 400px;
}

.submenu>div {
  width: 1200px;
}

.submenu a {
  text-decoration: none;
  color: #fff;
}

.submenu_item {
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
  padding: 5px 0px;
}

.submenu_subitem {
  font-size: 14px;
}

.submenu_item i,
.submenu_subitem i,
.mobileMenu_list_item i {
  font-size: 16px !important;
  color: #7431A8
}

.submenu_game_links:hover {
  color: #7431A8 !important
}

.submenu_section1 {
  padding-top: 40px;
  color: #fff;
}

.submenu_section2 {
  height: 100%;
  overflow: auto;
}

.submenu_section2>div {
  padding-top: 10px;
  overflow: hidden;
}

.submenu-link {
  font-size: 16px;
  font-family: "Lato", sans-serif !important;
  text-transform: uppercase;
  color: #fff;
  padding: 0 30px;
  line-height: 100%;
}

.submenu-link>div {
  min-height: 54px;
}

.submenu-content {
  height: max-content;
}

.hide {
  width: 0;
}

.showLevel {
  min-width: 70vw;
}

.sidebar_item {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-bottom-text {
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
}

.home-bottom-text h2 {
  color: #fff;
}

.header-area {
  position: sticky;
  transition: opacity 2s;
  background-color: #ffffff;
  background-position: center;
  background-size: cover;
  border-bottom: solid 1px #DCE2E9;
  z-index: 2;
}

#header,
#header .nav-bar {
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
}

#header .nav-link {
  color: #fff;
}

.home .header-area .logo {
  height: 0;
  overflow: hidden;
}

.home .header-area.sticky .logo,
.home-header-area .home-sticky .logo {
  height: auto;
}

.home-header-area {
  position: fixed;
  background-color: rgba(30, 27, 45, 0.3);
  z-index: 3;
  transition: background 1s;
}

.home-header-area .line {
  transition: opacity 1s;
  opacity: 0.3;
}

.home-header-area.home-sticky .line {
  opacity: 1;
}

.header-area .logo,
.home-logo {
  margin: 0 auto;
}

.sticky {
  z-index: 2;
  top: 0;
}

.home-sticky {
  z-index: 2;
  top: 0;
  background-color: rgba(30, 27, 45, 1) !important;
  transition: 1s;
}

.header-area.sticky .logo svg,
.home-header-area.home-sticky .home-logo svg {
  width: 222px;
}

.header-area .logo svg,
.home-logo svg {
  transition: width 0.5s;
  width: 300px;
  margin: 0 auto;
  display: block;
}

.nav-bar {
  display: flex;
  padding: 30px;
  justify-content: center;
  transition: padding 0.5s;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}

.nav-bar a {
  text-decoration: none;
}

.sticky .nav-link {
  padding: 13px;
}

.nav-bar .nav-link {
  font-size: 20px;
  font-family: "Lato", sans-serif !important;
  text-transform: uppercase;
  border-left: 1px solid #9396A5 !important;
  color: #000;
  padding: 5px 0px;
  min-width: max-content;
  line-height: 14px;
}

.with-submenu:first-child {
  border-left: none !important;
}

.nav-bar .nav-link:hover,
.submenu-link:hover,
.submenu-link:focus {
  color: #7431A8;
  cursor: pointer;
}

.nav-link:first-child {
  border: 0px;
}

.footer {
  position: relative;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  align-items: center;
  border-top: solid 1px #fff;
}

.footer-nav-link {
  font-size: 15px;
  font-family: "Lato" !important;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  padding: 0 30px;
  min-width: max-content;
  line-height: 14px;
  text-decoration: none;
}

.footer-navbar a:not(:last-child) {
  border-right: 1px solid #9396A5;
}

.footer_large_text {
  font-size: 22px;
}

.page-container {
  flex: 1;
  max-width: 1170px;
  margin: 0 auto;
}

.copyright {
  font-family: "Lato", sans-serif;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  max-height: 62px;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  font-size: 9px;
}

.n-logo-footer {
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 150px;
  position: absolute;
  top: -40px;
  width: 100px;
}

.footer-links {
  display: flex;
  justify-content: center;
}

.admiral-logo {
  height: 16px;
}

.admiralbet-logo {
  height: 23px;
}

.milenium-logo {
  height: 43px;
  margin: 10px 10px;
}

.blue {
  color: #002056 !important;
}

.blue:hover {
  color: #fff;
}

.footer-social {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 50px;
}

.footer-social li {
  display: inline-block;
  margin: 12px;
}

.footer-social li a {
  color: #fff;
  font-size: 16px;
}

.footer-social li a i {
  font-size: 18px !important;
}

.footer-social i:hover {
  color: #7431A8 !important;
}

.fa {
  font-size: 27px !important;
}

.white_background_container {
  color: #28262D;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px;
  flex: 1;
}

.white_background_section,
.gray_dots_background_section,
.clean_white_background_section {
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  z-index: 1;
}

.gray_dots_background_container {
  color: #00285e;
  text-align: center;
  background-image: url("../assets/purple_bgd.webp");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
}

.clean_white_background_container {
  color: #28262D;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
  flex: 1;
  position: relative;
}

.header-img {
  width: 100%;
}

.modal-body {
  padding: 0 !important;
}

.modal-header {
  display: none !important;
}

video {
  display: block;
}

h2 {
  color: #00285e;
  text-align: center;
  font-family: "Lato";
  font-weight: 600 !important;
  padding-top: 50px;
}

.new {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #00285E;
}

.news-content .middle .other-news,
.news-content .middle .subtitle {
  color: #00285E;
}

.news-content .middle .other-news-container {
  margin-top: 50px;
}

.news-content .middle .other-news .other-news-container {
  max-width: 350px;
  max-height: 360px;
}

.news-content .middle .other-news .other-news-container .image {
  position: relative;
}

.news-content .middle .other-news .other-news-container .tag-container {
  position: absolute;
  bottom: 10px;
  padding: 0px 7px;
  color: #fff;
}

.news-content .middle .other-news .other-news-container .tag-container .tag {
  width: max-content;
  border-radius: 5px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
}

.news-content .middle .other-news .other-news-container .date {
  color: #3666ae;
  font-size: 14px;
}

.news-container .small-img {
  width: 300px;
}

.news-container .description .image {
  position: relative;
  top: 20%;
}

.news-container .description .image .tag-container {
  /* position: absolute; */
  left: 5px;
  font-size: 12px;
  bottom: 5px;
  color: #fff;
}

.news-container .description .image .tag-container .tag {
  width: max-content;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  border-radius: 5px;
}

.other_news_title {
  font-weight: 600;
  color: #002056;
  /* word-break: break-all; */
}

.other_news_date {
  color: #3666ae;
  font-size: 12px;
}

.other_news .description .new-icon {
  position: absolute;
  width: 60px;
  z-index: 1;
}

.other_news .description .coming-soon-icon {
  position: absolute;
  width: 60px;
  z-index: 1;
}

.no-data {
  background: #5C5766;
  margin: 0 20px;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

.home-news .first-news-container {
  position: relative;
}

.home-news .first-news-container .new-icon {
  position: absolute;
}

.home-news .first-news-container .coming-soon-icon {
  position: absolute;
  top: 3px;
  left: 2px
}

.home-news .first-news-container .description {
  padding: 10px;
  position: absolute;
  bottom: 0;
  color: #fff;
  background: linear-gradient(0deg, #302949 .28%, #1f1c2e 16.23%, #0f0f11 38.83%, rgba(19, 16, 23, .89) 58.38%, rgba(30, 29, 34, .6) 68.02%, rgba(48, 45, 53, .38) 84.77%, rgba(61, 57, 66, 0));
}

.home-news .first-news-container .description .title {
  margin-bottom: 10px;
}

.home-news .first-news-container .description .tag {
  margin-bottom: 15px;
}

.home-news .first-news-container .description .date {
  font-size: 12px;
}

.home-news .first-news-container .description .tag>div {
  width: max-content;
  padding: 0px 7px;
  border-radius: 5px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  font-size: 14px;
}

.home-other-news .news-container {
  position: relative;
}

.home-other-news .news-container .tag {
  /* position: absolute; */
  bottom: 5%;
  color: #fff;
  padding: 0px 7px;
  border-radius: 5px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  font-size: 14px;
  left: 5px;
  width: fit-content;
}

.home-other-news .cover-image img {
  height: 130px;
  width: 175px;
}

.home-other-news .news-container .new-icon {
  position: absolute;
  width: 60px;
  left: 0;
}

.home-other-news .news-container .coming-soon-icon {
  position: absolute;
  width: 60px;
  left: 2px;
  top: 3px;
}

.home-other-news .news-container .info {
  margin-left: 10px;
  height: 100%;
}

.home-other-news .news-container .info .title {
  color: #00285e;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
}

.title-news {
  font-size: 14px !important;
}

.home-other-news .news-container .info .date {
  color: #3666ae;
  font-family: Lato;
  font-size: 14px;
  line-height: 141.5%;
}

.home-other-news .news-container:not(:first-child) {
  margin-top: 3px;
}

.single-news {
  position: relative;
  max-height: 460px;
}

.single-news .tag,
.new_tab_content .content .tag {
  width: max-content;
  padding: 0px 7px;
  border-radius: 5px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
}

.single-news .content {
  position: absolute;
  max-height: 230px;
  bottom: 0;
  color: #fff;
  background: linear-gradient(0deg, #302949 0.28%, #1F1C2E 16.23%, #0F0F11 38.83%, rgba(19, 16, 23, 0.89) 58.38%, rgba(30, 29, 34, 0.6) 68.02%, rgba(48, 45, 53, 0.38) 84.77%, rgba(61, 57, 66, 0) 100%);
}

.new_tab_content {
  width: 32rem !important;
}

.all-news {
  max-height: 1175px;
  overflow-y: scroll;
}

.all-news>div {
  width: max-content;
}

.all-news .item {
  width: 300px;
}

.all-news .item>div {
  position: relative;
}

.all-news .item>img {
  width: 100%;
}

.all-news .item .tag {
  position: absolute;
  bottom: 5%;
  color: #fff;
  padding: 0px 7px;
  border-radius: 5px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  font-size: 14px;
  left: 2%;
}

.all-news .item .text {
  font-family: 'Lato';
  font-weight: 500;
  font-size: 16px;
  color: #00285E;
}

.all-news .item .date {
  font-family: 'Lato';
  font-size: 12px;
  line-height: 141.5%;
  color: #3666AE;
}

.all-news .item:not(:first-child) {
  margin-top: 5px;
}

.news_page .popular .side-content {
  max-width: 300px;
}

.news_item_container {
  overflow: hidden;
  height: 100%;
  position: relative;
  max-width: min-content;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  height: min-content;
}

.firstNews-date {
  font-size: 12px;
}

.news_title {
  font-weight: 600 !important;
}

.news_detail_container .news_title {
  font-size: 20px;
}

.whitegray_background_container {
  color: #00285e;
  flex: 1;
}

.gray_background_section {
  background-repeat: no-repeat;
  background-size: cover;
}

.news_detail_container .whitegray_background_content {
  z-index: 1;
}

.whitegray_background_content {
  max-width: 1200px;
  position: relative;
  top: -100px;
}

.news_page {
  font-family: 'Lato'
}

.news_page .news-content {
  margin-bottom: 100px;
}

.news_page .title {
  margin-bottom: 100px;
}

.news_page .middle {
  border-left: 2px solid #00285E;
  /* border-right: 2px solid #00285E; */
}

.news_page .sidebar-container .icons {
  margin-right: -6px;
}

.news_page .left-side .side-tag {
  border: 1px solid #000;
  width: max-content;
  padding: 3px 5px;
  border-radius: 10px;
}

.news {
  padding: 30px 0;
  background-color: #eaeff5;
  text-align: left;
  padding: 20px;
  min-height: 280px;
}

.news_date {
  width: 100px;
  position: absolute;
  top: 34%;
}

.news_date>p {
  margin-bottom: 0px;
  color: #fff;
  padding-bottom: 5px;
  text-shadow: 1px 1px 1px #002056;
  font-weight: 500;

}

.news_date>div {
  background-color: #00285e;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 15px;
  font-size: 12px;
  padding: 5px 0px;
}

.card-title {
  line-height: 1.5em;
  height: 4em;
}

.card-text {
  color: #3D4247;
}

.games .card-img-top {
  overflow: hidden;
}

.product_card_container {
  max-height: 120px;
  max-width: 156px;
}

.product_card_container .card-body {
  min-width: 130px;
}

.automats .card-body {
  font-size: 18px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  border-radius: 6px !important;
}

.automats .card-img-top {
  padding-bottom: 30px;
}

.card-img-top-container {
  max-width: max-content;
  position: relative;
  width: 100%;
}

.card-img-top-container img {
  max-width: 230px;
  max-height: 350px;
}

.show-products-container img {
  max-height: 350px;
}

.coming-soon-img-cabinets {
  left: 15%;
  position: absolute;
  top: 5%;
  width: 28%;
}

.coming-soon-img-mixes {
  position: absolute;
  top: -5%;
  left: -5%;
}

.new-game-mix {
  position: absolute;
  top: 0%;
  left: 0%;
}

.all_mixes:hover .all_mixes div {
  color: red !important;
}

.automats .card-body>h5 {
  color: #fff;
  opacity: initial;
  font-size: 16px;
}

.automats .shadowtitle {
  margin: 0;
  padding: 0;

}

.news_section {
  max-width: 100%;
}

.news_title {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 15px;
  color: #002056;
}

.news_content {
  color: #3D4247;
  font-size: 14px;
}

.game .game_content {
  color: #3D4247;
  font-family: "Lato";
}

.game h4 {
  font-family: "Lato";
  font-weight: 600;
}

.btn_large {
  padding: 15px;
  width: 200px !important;
  text-align: center;
  font-size: 18px;
}

.btn_load_more {
  background-color: #252037;
  border: 1px solid #252037;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 7px;
  cursor: pointer;
  max-width: fit-content;
}

.hover_btn_load_more {
  background-image: linear-gradient(#fff, #fff);
  background-size: 100% 0%;
  background-repeat: no-repeat;
  transition: .4s;
}

.hover_btn_load_more:hover {
  background-size: 100% 100%;
  color: #252037 !important;
  border: 1px solid #252037;
}

.contact-page-container {
  color: #00285e;
  text-align: center;
  background-image: url("../assets/gray_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
}

.contact-page-section {
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  z-index: 1;
}

.contact-container {
  overflow: hidden;
}

.contact-form label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.105em;
  color: #000;
}

.contact-form input {
  border: 1px solid #3D4247;
}

.contact-form textarea {
  border: 1px solid #3D4247;
}

.form-control {
  height: 45px;
}

.form-control:last-child {
  height: auto;
}

.footer-navbar {
  text-align: center;
  margin-right: 0px !important;
}

.automats h2 {
  margin-bottom: 50px;
}

.animation {
  transition: 0.7s transform;
}

.animation:hover {
  transform: scale(1.1);
}

.pagination_items {
  color: #9a9a9a;
  padding-left: 10px;
  padding-right: 20px;
  text-decoration: none;
}

.pagination_items:hover {
  color: #00285e;
}

.active_page {
  color: #00285e;
  font-weight: bold;
}

.filter_box {
  height: 40px;
}

.filter_box>input,
.filter_box>i {
  border: none;
  background-color: #CFCFCF;
  padding-left: 7px;
}

.filter_box>i {
  padding-left: 10px;
  padding-top: 3px;
  color: #595959;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.filter_box>input {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  color: #3D4247;
}

.filter_box>input:focus {
  outline: none;
}

.filter_box input::placeholder {
  color: #595959;
}

.filter_container {
  margin-bottom: 100px;
}

.fa-search::before {
  font-size: 20px;
}

.search-site-container {
  position: absolute;
  right: 20px;
  top: 18px;
}

.search-site {
  margin-right: 15px;
}

.search-site i,
.search-site input {
  background: rgba(255, 255, 255, 0.38);
}

.search-site i {
  width: 33px;
  text-align: center;
  color: #C1C1C1;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.search-site input {
  color: #fff;
  width: 110px;
  line-height: 14px;
  font-size: 12px;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.search-site input:focus {
  outline: none;
}

.search-site input::placeholder {
  color: #C1C1C1;
}

.search-site-no-opacity i,
.search-site-no-opacity input {
  background-color: #DCDCDC;
  color: #000;
}

.search-site-no-opacity input::placeholder {
  color: #000;
}

.search-site-container .mobile-icon i {
  background: none;
  color: #fff;
}

.about {
  margin-top: 80px;
  position: relative;
}

.about img {
  width: 100%;
}

.about h5 {
  font-family: "Lato", sans-serif;
  color: #002056;
  padding-bottom: 15px;
}

.about h2 {
  text-align: left;
  padding-bottom: 20px;
  font-size: 28px;
  padding-top: 20px;
}

.career {
  background-color: #9a9a9a;
}

.videos-icon {
  margin-bottom: 50px;
}

.career h2 {
  text-align: left;
  padding-bottom: 20px;
}

.card_item {
  font-size: 15px;
  min-height: 22rem;
}

.card_item h5 {
  font-family: 'Lato';
  font-weight: 600;
}

.card-title {
  font-size: 20px;
  white-space: pre-line;
}

.card-text {
  font-size: 13px;
}

.grayBackground {
  background-color: #E5E5E2 !important;
  border-radius: 5px;
}

.date {
  max-width: 130px;
}

.blue_text {
  color: #3D4247;
}

.about_card_height {
  min-height: 250px;
}

.about_card_height h5 {
  font-family: 'Lato';
  font-weight: 600;
}

.thumbnail_container {
  max-width: 564px;
  margin: 0 auto;
}

.my-modal {
  width: 90vw;
  max-width: 90vw;
}

.column-count-2 {
  column-count: 2;
}

.facts_container {
  max-width: 350px;
}

.mix_container {
  max-width: 350px;
}

.about_tab {
  border: 1px solid #D2D2D2;
}

.radius-4 {
  border-radius: 4px;
}

.container .about .about_tab {
  margin-top: 35px;
}

.about-line {
  position: absolute;
  top: 15px;
  width: 15px;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translateX(-50%);
}

.about-line .line {
  width: 5px;
  background: linear-gradient(0deg, rgba(20, 23, 122, 1) 0%, rgba(255, 0, 250, 1) 100%);
  height: calc(100% - 499px);
  margin: 0 auto;
  display: inline-block;
}

.about-line .bullet-point {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  background-color: #000;
  border: 3px solid #fff;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.about-line .bullet-point:nth-child(2) {
  top: 470px;
}

.about-line .bullet-point:nth-child(3) {
  top: 905px;
}

.about-line .bullet-point:nth-child(4) {
  top: 1220px;
}

.about-line .bullet-point:nth-child(5) {
  top: 1745px;
}

.about-line .bullet-point:nth-child(6) {
  top: auto;
  bottom: 490px;
}

.career-line {
  position: absolute;
  top: 15px;
  width: 15px;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translateX(-50%);
}

.career-line .line {
  width: 5px;
  background: linear-gradient(0deg, rgba(20, 23, 122, 1) 0%, rgba(255, 0, 250, 1) 100%);
  height: calc(100% - 176px);
  margin: 0 auto;
  display: inline-block;
}

.career_page .career-line .bullet-point {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  background-color: #000;
  border: 3px solid #fff;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.career_page .career-line .bullet-point:nth-child(2) {
  top: 375px;
}

.career_page .career-line .bullet-point:nth-child(3) {
  top: 710px;
}

.career_page .career-line .bullet-point:nth-child(4) {
  top: 1010px;
}

.career_page .career-line .bullet-point:nth-child(5) {
  top: 1345px;
}

.about_tab_header {
  color: #fff;
  font-size: 26px;
  min-height: 44px;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  border-radius: 4px 4px 0px 0px;
}

.about-border {
  border: 1px solid #D1D1D1;
  border-radius: 6px;
}

.product_card_container h3 {
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-weight: 600;
}

.product_card_container .product_img {
  height: 50px;
}

.shadowtitle {
  padding: 1em;
}

.product_img {
  height: 75px;
  max-width: 156px !important;
}

.arrow {
  min-height: 30px;
  max-width: 30px;
}

.arrow-down {
  max-width: 40px;
}

.arrow-up {
  padding: 0;
  max-width: 40px;
  transform: rotate(0.50turn);
}

.arrow-right {
  padding: 0;
  max-width: 30px;
  transform: rotate(0.75turn);
}

.product_bg {
  background-image: url("/public/images/automats/main/a-bgd.webp");
}

.product_item_container {
  max-width: 275px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product_item_container .card-img-top {
  flex: 1;
}

.product_item_container .card-body {
  flex: 0;
  ;
}

.product_item_container .card-body {
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  color: #fff;
  border-radius: 0 0 6px 6px;
}

.product_container {
  padding: 0;
  position: relative;
  min-height: 150px;
  color: #00285e;
  text-align: center;
  padding-bottom: 50px;
  flex: 1;
  background: radial-gradient(35.58% 314.88% at 50% 50%, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  padding-bottom: 5px;
  overflow: hidden;
}

.line {
  height: 5px;
  background: linear-gradient(90deg, #FF00FA 0%, #CD0DF7 16%, #BE00FC 33%, #7D07FB 50%, #4C0DE9 67%, #2404F7 84%, #14177A 100%);
}

.product_section {
  max-width: 1200px !important;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}

.product_section .tab:first-child {
  margin-left: 6px;
}

.product_section .tab:last-child {
  margin-right: 6px;
}

.news_detail_container .whitegray_background_content {
  transform: translateY(-10%);
}

.news_detail_container h2 {
  margin-bottom: 150px;
}

.trim-8 {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.trim-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.trim-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.counter_container {
  margin-top: 100px !important;
}

.counter_container div canvas {
  height: 380px !important;
}

.counter {
  position: relative;
  max-width: 295px !important;
  opacity: 0.2;
  transition: 5s opacity;
}

.counter_animation {
  opacity: 1;
  transition: 3s;
  transform: translateY(-50px);
}

.counter>div {
  width: 65%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.counter_content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.counter_content p {
  margin: 0;
}

.counter_content .small_text {
  font-size: 20px;
}

.counter_content .large_text {
  font-size: 60px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

.decimal_text {
  font-size: 35px;
  font-weight: 500;
  padding-top: 6px;
}

.close_btn {
  font-size: 36px;
  position: relative;
  top: -10px;
}

.cabinets .shadowtitle {
  padding: 0px;
}

.cabinet_teaser {
  background: linear-gradient(90deg, #A6A6A4 0%, #C4C2BE 14.06%, #E3E3E0 24.48%, #F7F7F6 36.98%, #FFFFFE 47.92%, #F9F9F8 58.85%, #E9E9E8 70.83%, #D0D0CE 82.29%, #A6A6A3 99.98%, #ACACA9 99.99%);
  margin-bottom: 70px;
  margin-top: 30px;
  position: relative;
  height: 108px;
}

.cabinet_teaser .inner {
  width: 1200px;
  margin: 0 auto;
  min-height: 108px
}

.cabinet_left {
  width: 600px;
  display: flex;
  position: relative;
  z-index: 1;
}

.cabinet_left a {
  margin-right: 10px;
  width: 150px;
  height: 100px;
}

.cabinet_3d {
  max-width: fit-content;
  position: absolute;
  right: 0;
  bottom: -56px;
}

.cabinet_description_list {
  list-style-type: disc !important;
}

.cabinets_all {
  min-width: 310px;
}

.cabinets_all div:not(:last-child) {
  border-bottom: solid 1px #ddd;
}

.games_all {
  background: #E5E5E2;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
}

.game-mix div:hover {
  color: #7431A8 !important;
}

.game-mix img {
  height: 72px;
  width: 105px
}

.game-mix-platform {
  max-width: 100px;
}

.user_login {
  min-width: 310px !important;
  background: #E5E5E2;
  border: 1px solid #D1D1D1;
  border-radius: 5px;
  height: fit-content;
}

.descr_left li {
  padding-top: 10px;
  list-style-type: none;
}

.descr_right li {
  list-style-type: none;
}

.user_login input {
  padding: 10px 15px;
  font-size: 15px;
  border: 1px solid #DCE2E9;
}

.game_mix_jackpots {
  min-width: 310px !important;
  height: fit-content;
}

.game_mix_jackpots a {
  text-decoration: none;
}

.mixes .clean_white_background_container>img {
  padding-bottom: 0;
  max-width: 1200px;
}

.vertical_align_center {
  top: 50%;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullOverlay {
  background: url("../assets/overlay.webp");
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
}

.light_blue {
  color: #4BAEE2;
}

.fixed-height {
  height: 70px;
}

.list_padding {
  padding: 10px 0;
}

.news_detail_date {
  color: #3666AE
}

.vertical_Y_align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.grayBackground {
  background-color: #e9eff5;
}

.cabinet-slider {
  position: fixed;
  top: 50%;
  transform: translateX(-100%);
}

.cabinet-slider-animation {
  transform: translateX(0%);
}

.active-tab .card-body {
  color: #0064EA;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.active-tab .card-body h3 {
  font-weight: 600;
  color: #0064EA
}

.active-tab .product_img {
  height: 82px;
}

.show-products-container {
  padding: 50px 0px;
  min-height: 625px;
}

.show-products-container .mobile-cabinets1,
.show-products-container .mobile-cabinets2 {
  min-height: 525px;
  position: relative;
  left: -100%;
}

.show-products-container .mobile-cabinets1 .visible,
.show-products-container .mobile-cabinets2 .visible {
  transform: translateX(210%);
}

.show-products-container .cabinets>div {
  position: relative;
  left: -160%;
}

.show-products-container .cabinets>div.visible {
  left: 0;
}

.show-products-container .cabinets>div.fade-in {
  transition: left 1s;
  left: 0;
}

.show-in {
  transition: 1s;
  transform: translateX(100%);
}

.show-in-2 {
  transition: 1s;
  opacity: 1 !important;
  transform: translateX(200%);
}

.show-products-container>div {
  width: 1200px;
}

.show-products-container .title {
  font-family: 'Lato';
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #00285E;
  /* padding-top: 20px; */
}

.show-products-container .games .title,
.about_item_container .title {
  color: #fff;
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  bottom: 0;
  border-radius: 0px 0px 8px 8px;
}

.show-products-container .games .img-container {
  max-width: 320px;
  max-height: 185px;
}

.show-products-container .games .title {
  height: 55px;
}

/* .show-products-container .cabinets img,
.mobile-cabinets1 .cabinets img,
.mobile-cabinets2 .cabinets img {
  max-height: 409px;
  width: auto;
} */

.mobileFirstImage {
  height: 40px;
}

.intro-section>div {
  color: #fff;
  max-width: 500px;
  text-align: center;
  position: relative;
  top: 88%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
}

.contact-address {
  max-width: 1200px;
  color: #000;
  font-family: 'Lato';
  font-size: 20px;
  line-height: 209%;

  letter-spacing: 0.105em;
}

.contact-title {
  color: #000;
  font-family: 'Lato';
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.contact-card {
  width: 100%;
  margin-left: 40px;
}

.chat-container {
  width: max-content;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 30px;
  z-index: 2;
}

.gallery_container {
  position: relative;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.gallery-close-button {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.gallery-close-button .line1,
.gallery-close-button .line2 {
  background-color: #fff;
  width: 25px;
  height: 3px;
  margin: 6px 0;
}

.gallery-close-button .line1 {
  transform: translate(0, -2px) rotate(-45deg);
}

.gallery-close-button .line2 {
  transform: translate(0, -11px) rotate(45deg);
}

.image-gallery {
  position: fixed;
  width: 70vw;
  left: 50%;
  top: 50%;
  z-index: 4;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, #302949 0%, #1F1C2E 16%, #0F0F11 33%, #131017 50%, #1E1D22 67%, #302D35 84%, #3D3942 100%);
  display: none;
}

.image-gallery-image {
  height: 70vh;
  padding: 20px 0px;
}

.image-gallery-slide-wrapper button:hover {
  color: #7431A8;
}

.showGallery {
  display: block;
}

.react-select-container .css-1p1elsy-control {
  background-color: #CFCFCF;
}

.home-about-container,
.contact-form-container,
.support-container {
  padding: 70px 0;
}

.cabinets-container,
.game-container {
  padding-bottom: 70px;
}

.cabinets-container .product_item_container {
  position: relative;
  /* left: calc(-1200px + 50px) */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cabinets-container .product_item_container .card-body {
  flex: 0 !important;
}

.cabinet-row-container .product_item_container.fade-in {
  transition: left 1s, opacity 1s;
  opacity: 1 !important;
  left: 0% !important;
}

.rotate_180 {
  transform: rotate(180deg);
}

.modal-body>img {
  max-width: 100%;
}

.new_tag {
  position: absolute;
}

.new-tag {
  background: #37343c;
  color: white;
  padding: 5px;
  border-radius: 4px;
}

.youtube_video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 1000px;
  height: 500px;
}

.cabinet-list-container {
  position: relative;
  left: -200%;
  opacity: 0;
}

.categories div {
  width: max-content;
  border-bottom: 2px solid #fff;
}

.categories div:hover {
  border-bottom: 2px solid #00285E;
}

@media(min-width: 576px) {
  .all-news {
    max-height: 1200px;
  }
}

@media(min-width: 768px) {
  .cabinet_teaser .inner {
    position: relative;
  }
}

@media (max-width: 1200px) {
  .all-news {
    max-height: 1200px;
  }

  .news_page .middle {
    border-left: none;
    border-right: none;
  }

  .show-in-2 {
    transition: 1s;
    opacity: 1 !important;
    transform: translateX(50%);
  }

  .cabinet-list-container {
    position: relative;
    left: -50%;
    opacity: 0;
  }

  .cabinet_teaser .inner {
    width: 100%;
  }

  .about-line {
    display: none;
  }

  .career-line {
    display: none;
  }

  .product_card_container .card-body {
    min-width: 80px;
  }

  .product_card_container h3 {
    font-size: 9px;
  }

  .search-site-container {
    top: 8px;
  }

  .chat-container {
    margin: 30px 20px;
  }

  .chat-card {
    width: 100%;
    margin-left: 20px;
  }

  .intro-section {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    height: 50vh;
  }

  .intro-section>div {
    padding: 0 20px;
  }

  .cover_image p {
    font-size: 12px;
    width: 100%;
    padding: 0 30px;
  }

  .footer a.nav-link {
    padding: 0 10px;
    margin: 10px 0;
  }

  .game {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .card-img-top-container {
    max-width: max-content;
    position: relative;
    width: 75%;
  }

  .coming-soon-img-mixes {
    position: absolute;
    top: -10%;
  }

  .youtube_video {
    width: 100%;
    height: 300px;
  }

  .product_container {
    min-height: 130px;
  }

  .home-header-area {
    background-color: rgb(61 57 65);
  }

  .home_page {
    padding-top: 55.6px;
  }

  .show-products-container .mobile-cabinets1,
  .show-products-container .mobile-cabinets2 {
    min-height: 330px;
  }

  .show-products-container .mobile-cabinets1 div,
  .show-products-container .mobile-cabinets2 div {
    padding: 15px 5px;
    /* height: 90px; */
  }

  .show-products-container .mobile-cabinets2 .btn_load_more {
    margin-top: 30px !important;
  }

  .show-products-container .gallery_tab .slider,
  .show-products-container .news_tab .slider {
    width: 17rem !important;
  }

  .show-products-container .games>div {
    margin-bottom: 70px;
  }

  .show-products-container {
    min-height: 0;
  }

  .show-products-container .games div {
    padding-bottom: 10px;
  }

  .cabinet_teaser .inner {
    background: linear-gradient(90deg, #A6A6A4 0%, #C4C2BE 14.06%, #E3E3E0 24.48%, #F7F7F6 36.98%, #FFFFFE 47.92%, #F9F9F8 58.85%, #E9E9E8 70.83%, #D0D0CE 82.29%, #A6A6A3 99.98%, #ACACA9 99.99%);
  }

  .gray_dots_background_container {
    background-image: url("../assets/purple_bg_mobile.webp");
  }

  .contact-page-container {
    background-image: url("../assets/gray_bg_mobile.webp");
  }

  .landing-animation,
  .landing-animation canvas {
    background: #0442A7 !important;
  }

  .contact-address {
    font-size: 18px;
  }

  .google-map-container {
    height: 400px !important;
  }

  .contact-container .contact-title {
    font-weight: 600;
    font-size: 22px;
  }

  .contact-container .contact-card div {
    font-size: 18px;
  }

  .contact-card {
    margin-left: 20px;
  }

  .contact-form label {
    font-size: 14px;
  }

  .image-gallery {
    width: 100vw;
  }

  .counter_container div canvas {
    height: 290px !important;
  }

  .news-container .description {
    top: 10%
  }

  .single-news .content {
    padding-top: 5px;
    font-size: 11px;
    height: auto;
  }

  .new_tab_content {
    width: 20rem !important;
  }

  .news_detail_container .whitegray_background_content {
    transform: translateY(-6%);
  }

  .footer-social li {
    margin: 5px;
  }

  .copyright {
    font-size: 11px;
  }

  .game {
    position: relative;
    width: 100%;
  }

  .game video {
    width: 100%;
    height: 100%;
  }

  .btn {
    font-size: 14px !important;
  }

  .cover_image p {
    font-size: 10px;
    width: 100%;
    padding: 0px 30px;
  }

  .about video {
    width: 250px;
    height: 166px;
  }

  .about_item_container>img {
    max-width: 308px !important;
  }

  .news_date {
    top: 28%;
  }

  .btn_more {
    font-size: 12px !important;
  }

  .card-text {
    font-size: 14px;
  }

  .news_detail_content {
    font-size: 12px;
  }

  .news_detail_container h2 {
    margin-bottom: 120px;
  }

  .home-bottom-text p {
    font-size: 11px;
    font-weight: 500;
  }

  .filter_box>input {
    width: 100%;
  }

  .product_item_container h5 {
    font-size: 14px;
  }

  .card_item {
    margin-top: 10px;
    font-size: 15px;
  }

  .card-title {
    line-height: 1.5em;
    font-size: 18px;
  }

  .footer a.nav-link {
    font-size: 15px;
  }

  .footer-navbar a {
    font-size: 14px;
  }

  .footer-admiral-logo {
    left: 0%;
  }

  .cabinet_teaser {
    background: none;
    margin-bottom: 0;
  }

  .cabinet_teaser .inner {
    text-align: center;
  }

  .cabinet_teaser {
    height: 650px;
  }

  .cabinet_3d {
    max-width: none;
    bottom: 35px;
  }
}

@media (max-width: 575px) {
  .home .header-area {
    height: auto;
  }

  .cover_image p {
    font-size: 11px;
    width: 100%;
    padding: 25px 20px;
  }

  .games .card-title {
    font-size: 12px;
  }

}

@media (max-width: 400px) {
  .game video {
    height: 210px;
  }

  .cover_image p {
    font-size: 10px;
    width: 100%;
    padding: 30px 0px;
  }

  .game .game_content {
    font-size: 12px;
  }

  .games .card-title {
    font-size: 10px;
  }

  .card-img-top-container .cabinet-image {
    max-height: 350px;
    max-width: 230px;
  }


  .home-cabinet-image {
    max-height: 350px;
  }
}

.aspect-ratio-game-mixes-cabinets {
  aspect-ratio: 14/9;
}

@media (max-width: 767px) {
  .aspect-ratio-game-mixes-cabinets  {
    aspect-ratio: 14/10;
  }
}